import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AssetCategory } from '@models/asset';
import { EventAsset } from '@models/event-asset';
import { QuantityUnitsService } from '@services/quantity-units.service';
import { EventLegacyFieldComponent } from '../event-legacy-field.component';

@Component({
  selector: 'app-event-material',
  templateUrl: './event-material.component.html',
  styleUrls: ['./event-material.component.scss', '../event-legacy-field.component.sass']
})
export class EventMaterialComponent extends EventLegacyFieldComponent implements OnInit {

  @Input() materials: EventAsset[];
  @Input() materialsLoaded: boolean;

  quantitiesUnits = {}

  constructor(
    protected modalController: ModalController,
    private quantityUnitsService: QuantityUnitsService
  ) { 
    super(
      modalController,
    )
  }

  ngOnInit() {
    this.quantityUnitsService.watchQuantityUnits.subscribe((quantityUnits) => {
      this.quantitiesUnits = quantityUnits;
    })
    this.materials = this.materials.filter((material) => !material.isArchived)
  }

  getAssetCategory(): typeof AssetCategory {
    return AssetCategory;
  }
}
