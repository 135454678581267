import { Labelable } from './labelable';
import { Identifiable } from './identifiable';

export class TaskAsset implements Labelable, Identifiable {
  assetId: string;
  amount: number;
  duration: number;
  name?: string;
  quantityUnit: string;
  jobRole?: string;
  supplier?: string;
  isArchived: boolean;

  constructor() {
    this.assetId = '';
    this.amount = 0;
    this.duration = 0;
    this.quantityUnit = null;
    this.isArchived = false;
  }

  /**
   * Extract {@link TaskAsset} array from json
   * @param json given json
   */
  public static jsonToTaskAssets(json): Array<TaskAsset> {
    return json.map(taskAssetObject => {
      const taskAsset: TaskAsset = new TaskAsset();
      taskAsset.assetId = taskAssetObject.assetId;
      taskAsset.amount = taskAssetObject.amount;
      taskAsset.duration = taskAssetObject.duration;
      taskAsset.quantityUnit = taskAssetObject.quantityUnit;
      taskAsset.isArchived = taskAssetObject.isArchived;
      return taskAsset;
    });
  }

  /**
   * Get json from {@link TaskAsset} array
   * @param taskAssets given taskAsset array
   */
  public static taskAssetsToDto(taskAssets: TaskAsset[]) {
    return taskAssets.map(taskAsset => {
      const json: any = {};
      json.assetId = taskAsset.assetId;
      json.amount = taskAsset.amount;
      json.duration = taskAsset.duration;
      json.quantityUnit = taskAsset.quantityUnit;
      json.isArchived = taskAsset.isArchived;
      return json;
    });
  }

  /**
   * Sort {@link TaskAsset} by name
   * @param asset1 first asset to compare
   * @param asset2 second asset to compare
   */
  public static compareByName(asset1, asset2) {
    return asset1.name.localeCompare(asset2.name);
  }

  /**
   * Copy amount and duration from one asset to to another.
   */
  public static copyState(from: TaskAsset, to: TaskAsset) {
    to.amount = from.amount;
    to.duration = from.duration;
  }

  public get label(): string {
    return this.name;
  }

  /**
   * /!\ Warning /!\ This is a helper ID and is not the same as the ID used in the backend
   */
  public get id(): string {
    return this.assetId;
  }
}
