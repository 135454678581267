import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { DeviceService } from '@services/device.service';
import { SummaryConfigurationPopupComponent } from '../summary-configuration-popup.component';
import { ReportFields } from '../../event-report-filter/event-report-filter.component';

@Component({
  selector: 'app-summary-configuration-popup-mobile',
  templateUrl: './summary-configuration-popup-mobile.component.html',
  styleUrls: ['./summary-configuration-popup-mobile.component.sass']
})
export class SummaryConfigurationPopupMobileComponent extends SummaryConfigurationPopupComponent {
  public isCollapsedDate: boolean = false;
  public isCollapsedTitle: boolean = false;
  public isCollapsedGeneral: boolean;
  public isCollapsedData: boolean;
  selectedOptions: string[] = [ReportFields.CONTRACTORS, ReportFields.PHOTOS, ReportFields.PEOPLE, ReportFields.EQUIPMENT, ReportFields.MATERIAL];

  mandatoryFields = [
    { label: 'events.list.site_name' },
    { label: 'label.date' },
    { label: 'summary-report.site_diary.prepared_by' },
    { label: 'report.weather' }
  ];

  activitiesFields = [
    { controlName: ReportFields.CONTRACTORS, label: 'report.contractors' },
    { controlName: ReportFields.PHOTOS, label: 'events.preview.section.photos' }
  ];

  resourcesFields = [
    { controlName: ReportFields.PEOPLE, label: 'report.people' },
    { controlName: ReportFields.EQUIPMENT, label: 'report.equipment' },
    { controlName: ReportFields.MATERIAL, label: 'report.materials' }
  ];

  constructor(
    protected formBuilder: FormBuilder,
    public deviceService: DeviceService,
  ) {
    super(
      formBuilder,
      deviceService,
    );
  }

  toggleSelection(option: string) {
    const index = this.selectedOptions.indexOf(option);
    
    if (index > -1) {
      this.selectedOptions.splice(index, 1); // Remove if already selected
    } else {
      this.selectedOptions.push(option); // Add if not selected
    }
  }
}
