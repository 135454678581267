import { OnInit, Input, Directive } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SummaryType } from '@services/export.service';
import { DeviceService } from '@services/device.service';

type SuccessCallback = (type: SummaryType, fromDate: Date, title: string, toDate: Date) => void;
type MobilerSuccessCallback = (type: SummaryType, fromDate: Date, title: string, toDate: Date, selectedReportFields: string[]) => void;
type DismissCallback = () => void;

/**
 * "6 days" in milliseconds.
 * Using 6 days instead of 7 is necessary to properly represent the actual range.
 * I.e. a weekly report starting on the `09` will compute data from `09` to `15` (7 days including `09`), and not `16`.
 */
const WEEK_MS = 518400000;
@Directive()
export abstract class SummaryConfigurationPopupComponent implements OnInit {
  @Input() protected successCallback: SuccessCallback;
  @Input() protected mobileSuccessCallback: MobilerSuccessCallback;
  @Input() protected dismissCallback: DismissCallback;

  public formGroup: FormGroup;
  protected localizedFormat: string;

  public fromDate: Date;
  public toDate: Date;
  public isDateChosen: boolean = false;
  public isDateDurationInvalid: boolean = false;
  public isDateRangeInvalid: boolean = false;
  public customSummaryEndDateInvalid: boolean = false;

  constructor(
    protected formBuilder: FormBuilder,
    public deviceService: DeviceService,
  ) { }

  private addOneWeek(strDate: string): string {
    const addedDate = new Date(new Date(strDate).getTime() + WEEK_MS);
    const date = addedDate.getDate();
    const month = addedDate.getMonth();
    const year = addedDate.getFullYear();
    return `${year}-${month + 1}-${date}`;
  }

 ngOnInit() {
    this.formGroup = this.formBuilder.group({
      type: ['daily', Validators.required],
      startdate: [null, Validators.required],
      enddate: [],
      title: [''],
    });

    this.formGroup.controls['startdate'].valueChanges.subscribe(startdate => {
      if (this.formGroup.controls['type'].value === 'weekly') {
        if (startdate !== null)
        {
          this.formGroup.controls['enddate'].setValue(this.addOneWeek(startdate));
        }
      }
    });

    this.formGroup.controls['type'].valueChanges.subscribe(type => {
      if (type !== 'custom') {
        this.isDateRangeInvalid = false;
        this.isDateDurationInvalid = false;
        this.customSummaryEndDateInvalid = false;
      }
      if (type === 'weekly') {
        if (this.formGroup.controls['startdate'].value !== null) {
          this.formGroup.controls['enddate'].setValue(this.addOneWeek(this.formGroup.controls['startdate'].value));
        } else if (this.formGroup.controls['enddate'].value !== null && this.formGroup.controls['startdate'].value === null) {
          this.formGroup.controls['enddate'].setValue(null);
        }
      } else if (type === 'daily') {
        this.formGroup.controls['enddate'].setValue(null);
      }
    });

    // This subscription is needed to check the conditions without updating the form group control values
    this.formGroup.valueChanges.subscribe(form => {
      if (form.type === 'custom') {
        if (form.enddate !== null && form.startdate !== null) {
          this.customSummaryEndDateInvalid = false;
          const strDateObject = new Date(form.startdate);
          const endDateObject = new Date(form.enddate);
          this.isDateDurationInvalid = (form.startdate > form.enddate);
          this.isDateRangeInvalid = (Math.abs(strDateObject.getTime() - endDateObject.getTime()) / (1000 * 60 * 60 * 24) > 30);
        } else if (form.enddate === null) {
          this.customSummaryEndDateInvalid = true;
        }
      }
    })
  }

  setCallbacks(
    success: SuccessCallback,
    dismiss?: DismissCallback,
  ): void {
    this.successCallback = success;
    this.dismissCallback = dismiss;
  }

  dismiss(): void {
    if (this.dismissCallback) {
      this.dismissCallback();
    }
  }

  isExportButtonDisabled(): boolean {
    return (this.formGroup.invalid || this.isDateRangeInvalid || this.isDateDurationInvalid || this.customSummaryEndDateInvalid);
  }

  getTooltipText(): string {
    return (this.customSummaryEndDateInvalid && this.formGroup.controls['startdate'].value !== null) ? ('form.error.event-and-task.export-tooltip') : '';
  }

  download(domEvent?: Event): void {
    if (domEvent) {
      domEvent.preventDefault();
    }
    if (!this.formGroup.invalid) {
      const { title, startdate, type, enddate } = this.formGroup.value;
      const newDate = new Date(startdate);
      newDate.setSeconds(0);
      newDate.setMinutes(0);
      newDate.setHours(0);
      const newEndDate = new Date(enddate);
      newEndDate.setSeconds(0);
      newEndDate.setHours(0);
      newEndDate.setMinutes(0);
      this.successCallback(type, newDate, title, newEndDate);
    }
  }

  exportMobileSummaryPdf(selectedReportFields: string[]) {
    if (!this.formGroup.invalid) {
      const { title, startdate, type, enddate } = this.formGroup.value;
      this.mobileSuccessCallback(type, startdate, title, enddate, selectedReportFields);
    }
  }
}
