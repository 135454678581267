import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Contractor } from '@models/contractor';
import { EventLegacyFieldComponent } from '../event-legacy-field.component';

@Component({
  selector: 'app-event-contractor',
  templateUrl: './event-contractor.component.html',
  styleUrls: ['./event-contractor.component.scss', '../event-legacy-field.component.sass']
})
export class EventContractorComponent extends EventLegacyFieldComponent implements OnInit {

  @Input() contractors: Contractor[];
  @Input() contractorsLoaded: boolean;

  constructor(
    protected modalController: ModalController,
  ) { 
    super(
      modalController,
    )
  }

  ngOnInit() {
    this.contractors = this.contractors.filter((contractor) => !contractor.isArchived)
  }

}
