import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { LottieModule } from 'ngx-lottie'
import { LoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxViewerModule } from 'ngx-viewer';
import { AssetsComponent } from '../assets/assets.component';
import { DatatableFooterComponent } from '../datatable/datatable-footer/datatable-footer.component';
import { DatatablePagerComponent } from '../datatable/datatable-pager/datatable-pager.component';
import { CssVariableDirective } from '../directives/css-variable.directive';
import { ResizeTextareaDirective } from '../directives/resize-textarea.directive';
import { ResizableTextareaComponent } from '../form/controls/resizable-textarea/resizable-textarea.component';
import { DurationPickerComponent } from '../form/controls/shared/duration-picker/duration-picker.component';
import { EditSiteComponent } from '../home/site/edit-site/edit-site.component';
import { TaskProgressBarComponent } from '../home/site/task/task-progress-bar/task-progress-bar.component';
import { MaterialModule } from '../material/material.module';
import { DurationPipe } from '../pipes/duration.pipe';
import { FilterEventByDatePipe } from '../pipes/filter-event-by-date.pipe';
import { FilterEvents } from '../pipes/filter-events.pipe';
import { FilterListByLabelPipe } from '../pipes/filter-list-by-label.pipe';
import { FilterTaskByDatePipe } from '../pipes/filter-task-by-date.pipe';
import { GroupByPipe } from '../pipes/group-by.pipe';
import { HourDiffBetweenTwoDatesPipe } from '../pipes/hour-diff-between-two-dates';
import { InitialUppercasePipe } from '../pipes/initial-uppercase.pipe';
import { JoinPipe } from '../pipes/join.pipe';
import { JulDatePipe } from '../pipes/jul-date.pipe';
import { ListToLabelListPipe } from '../pipes/list-to-label-list.pipe';
import { NameFromUserIdPipe } from '../pipes/name-from-user-id.pipe';
import { OrderUsersByMailPipe } from '../pipes/order-users-by-mail.pipe';
import { SearchFilterPipe } from '../pipes/search-filter.pipe';
import { SecureImageSrcPipe } from '../pipes/secure-image.pipe';
import { EventStatusToIonColorPipe } from '../pipes/event-status-to-ion-color.pipe';
import { EventStatusToLabelTranslationKeyPipe } from '../pipes/event-status-to-label-translation-key.pipe';
import {
  ConfirmModalComponent,
  ConfirmService,
  ConfirmState,
  ConfirmTemplateDirective
} from '../services/modals/modal.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { GroupSpaceUserByUserIdPipe } from './../pipes/groupe-space-user-by-user-id.pipe';
import { WeatherTemperaturePipe } from './../pipes/weather-temperature.pipe';
import { WeatherWindspeedPipe } from './../pipes/weather-windspeed.pipe';
import { ApproveButtonComponent } from './components/buttons/approve-button/approve-button.component';
import { BackButtonComponent } from './components/buttons/back-button/back-button.component';
import { CancelButtonComponent } from './components/buttons/cancel-button/cancel-button.component';
import { DeleteButtonComponent } from './components/buttons/delete-button/delete-button.component';
import { RejectButtonComponent } from './components/buttons/reject-button/reject-button.component';
import { SaveButtonComponent } from './components/buttons/save-button/save-button.component';
import { FabButtonComponent } from './components/fab-button/fab-button.component';
import { MobileToolbarComponent } from './components/mobile-toolbar/mobile-toolbar.component';
import { DraftButtonComponent } from './components/buttons/draft-button/draft-button.component';
import { SubmitButtonComponent } from './components/buttons/submit-button/submit-button.component';
import { CurrencyPipe } from 'app/pipes/currency.pipe';
import { EventsFormMobileToolbarComponent } from './components/events-form-mobile-toolbar/events-form-mobile-toolbar.component';
import player from "lottie-web";
import { TaskStatusOrIndicatorToCssClassPipe } from 'app/pipes/task-status-or-indicator-to-css-class.pipe';
import { TaskNewStatusToCssClassPipe } from 'app/pipes/task-new-status-to-css-class.pipe';
import { SelectTaskComponent } from 'app/form/controls/select-task/select-task.component';
import { ImagesInputComponent } from 'app/form/controls/shared/images-input/images-input.component';
import { CssClassToAppSpecificColorCssClass } from 'app/pipes/css-class-to-app-specific-color-css-class.pipe';
import { PhoneNumberMaskPipe } from 'app/pipes/phone-number-mask.pipe';
import { PhoneNumberVerificationModalComponent } from 'app/modals/2FA/phone-number-verification/phone-number-verification-modal/phone-number-verification-modal.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { GoogleMapsModule, } from '@angular/google-maps'
import { NotifiedAtPipe } from 'app/pipes/notified-at.pipe';
import { TaskNumberPipe } from 'app/pipes/task-number.pipe';
import { TaskToLabelListPipe } from 'app/pipes/task-to-label-list.pipe';
import { EventSearchFilterService } from '@services/event-search-filter.service';
import { UiDatetimePickerService } from 'app/core/ui/services';
import { TaskFilterService } from '@services/task-filter.service';
import { HelpGuidesModalComponent } from 'app/modals/on-boarding/admin-onboarding-get-started-page/help-guide-modal/help-guides-modal.component';
import { AdminOnboardingModalComponent } from 'app/modals/on-boarding/admin-onboarding-modal/admin-onboarding-modal.component';
import { OnboardingMobileComponent } from 'app/modals/on-boarding/onboarding-mobile/onboarding-mobile.component';
import { OnBoardingWebappComponent } from 'app/modals/on-boarding/on-boarding-webapp/on-boarding-webapp.component';
import { ApplicationAccessModalComponent } from 'app/modals/on-boarding/admin-onboarding-get-started-page/application-access-modal/application-access-modal.component';
import { UserSearchFilterPipe } from 'app/pipes/user-search-filter.pipe';
import { ArchiveFilterPipe } from 'app/pipes/archive-filter-pipe';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    ConfirmModalComponent,
    AssetsComponent,
    DatatableFooterComponent,
    DatatablePagerComponent,
    ConfirmTemplateDirective,
    EditSiteComponent,
    HourDiffBetweenTwoDatesPipe,
    DurationPipe,
    JoinPipe,
    JulDatePipe,
    ListToLabelListPipe,
    FilterListByLabelPipe,
    NameFromUserIdPipe,
    FabButtonComponent,
    GroupByPipe,
    GroupSpaceUserByUserIdPipe,
    WeatherTemperaturePipe,
    WeatherWindspeedPipe,
    FilterEventByDatePipe,
    FilterEvents,
    FilterTaskByDatePipe,
    OrderUsersByMailPipe,
    ResizeTextareaDirective,
    SearchFilterPipe,
    UserSearchFilterPipe,
    SecureImageSrcPipe,
    CssVariableDirective,
    DurationPickerComponent,
    ResizableTextareaComponent,
    TaskProgressBarComponent,
    ImagesInputComponent,
    SelectTaskComponent,
    SpinnerComponent,
    EventStatusToIonColorPipe,
    EventStatusToLabelTranslationKeyPipe,
    TaskStatusOrIndicatorToCssClassPipe,
    TaskNewStatusToCssClassPipe,
    CssClassToAppSpecificColorCssClass,
    InitialUppercasePipe,
    CurrencyPipe,
    PhoneNumberMaskPipe,
    MobileToolbarComponent,
    SaveButtonComponent,
    DeleteButtonComponent,
    RejectButtonComponent,
    ApproveButtonComponent,
    CancelButtonComponent,
    BackButtonComponent,
    DraftButtonComponent,
    SubmitButtonComponent,
    EventsFormMobileToolbarComponent,
    PhoneNumberVerificationModalComponent,
    NotifiedAtPipe,
    TaskNumberPipe,
    TaskToLabelListPipe,
    AdminOnboardingModalComponent,
    HelpGuidesModalComponent,
    OnBoardingWebappComponent,
    OnboardingMobileComponent,
    TaskToLabelListPipe,
    ApplicationAccessModalComponent,
    ArchiveFilterPipe
  ],
  imports: [
    TranslateModule.forChild(),
    IonicModule,
    CommonModule,
    UiSwitchModule.forRoot({
        size: 'small',
        color: '#009688'
    }),
    AngularMultiSelectModule,
    NgSelectModule,
    NgxViewerModule,
    LoadingModule,
    NgbModule,
    GoogleMapsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxDatatableModule,
    NgxMaskModule.forRoot(),
    LottieModule.forRoot({player: playerFactory}),
    NgxIntlTelInputModule,
  ],
  providers: [
    ConfirmService,
    ConfirmState,
    SecureImageSrcPipe,
    FilterEvents,
    CssClassToAppSpecificColorCssClass,
    NotifiedAtPipe,
    EventSearchFilterService,
    TaskFilterService,
    UiDatetimePickerService,
    TaskStatusOrIndicatorToCssClassPipe
  ],
  exports: [
    TranslateModule,
    AngularMultiSelectModule,
    NgSelectModule,
    NgxViewerModule,
    UiSwitchModule,
    HourDiffBetweenTwoDatesPipe,
    DurationPipe,
    JoinPipe,
    JulDatePipe,
    FilterListByLabelPipe,
    ListToLabelListPipe,
    NameFromUserIdPipe,
    GroupByPipe,
    GroupSpaceUserByUserIdPipe,
    WeatherTemperaturePipe,
    WeatherWindspeedPipe,
    FilterEventByDatePipe,
    FilterEvents,
    FilterTaskByDatePipe,
    OrderUsersByMailPipe,
    SecureImageSrcPipe,
    ConfirmModalComponent,
    AssetsComponent,
    DatatableFooterComponent,
    DatatablePagerComponent,
    ConfirmTemplateDirective,
    GoogleMapsModule,
    NgbModule,
    ResizeTextareaDirective,
    CssVariableDirective,
    NgxDatatableModule,
    SearchFilterPipe,
    UserSearchFilterPipe,
    FabButtonComponent,
    NgxMaskModule,
    SearchFilterPipe,
    DurationPickerComponent,
    ResizableTextareaComponent,
    TaskProgressBarComponent,
    SelectTaskComponent,
    ImagesInputComponent,
    SpinnerComponent,
    EventStatusToIonColorPipe,
    EventStatusToLabelTranslationKeyPipe,
    TaskStatusOrIndicatorToCssClassPipe,
    TaskNewStatusToCssClassPipe,
    CssClassToAppSpecificColorCssClass,
    InitialUppercasePipe,
    CurrencyPipe,
    PhoneNumberMaskPipe,
    MobileToolbarComponent,
    SaveButtonComponent,
    DeleteButtonComponent,
    RejectButtonComponent,
    ApproveButtonComponent,
    CancelButtonComponent,
    BackButtonComponent,
    DraftButtonComponent,
    SubmitButtonComponent,
    EventsFormMobileToolbarComponent,
    PhoneNumberVerificationModalComponent,
    NgxIntlTelInputModule,
    NotifiedAtPipe,
    TaskNumberPipe,
    TaskToLabelListPipe,
    AdminOnboardingModalComponent,
    HelpGuidesModalComponent,
    OnBoardingWebappComponent,
    OnboardingMobileComponent,
    ApplicationAccessModalComponent,
    ArchiveFilterPipe
  ]
})
export class SharedModule { }
