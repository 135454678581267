<ng-container>
  <ion-header class="secondary-header" mode="ios">
    <app-mobile-toolbar
      doneLabel="btn.export"
      title="events.export.pdf.summary.popup.header"
      [disableDoneButton]="isExportButtonDisabled()"
      (back)="dismiss()"
      (done)="exportMobileSummaryPdf(selectedOptions)">
    </app-mobile-toolbar>
  </ion-header>

  <ion-content class="main-form-wrapper whitesmoke-background">
    <form [formGroup]="formGroup">
      <ion-item-divider>
        <ion-label
          (click)="isCollapsedGeneral = !isCollapsedGeneral"
          [attr.aria-expanded]="!isCollapsedGeneral"
          aria-controls="collapseGeneral">
          <h1 class="pull-left">
            {{'events.export-report-general' | translate}}
          </h1>
          <ion-icon class="pull-right" [attr.name]="isCollapsedGeneral ? 'chevron-down' : 'chevron-up'"></ion-icon>
        </ion-label>
      </ion-item-divider>

      <div class="seperator"></div>
      
      <div id="collapseGeneral" [ngbCollapse]="isCollapsedGeneral">
        
        <ion-item-divider>
          <ion-label>
            <h2>
              {{'events.export.pdf.summary.popup.type-label.short' | translate}}
            </h2>
          </ion-label>
        </ion-item-divider>
        <ion-list>
          <ion-radio-group name="type" formControlName="type">
            <ion-item class="form-input" lines="none">
              <ion-radio mode="md" value="daily"></ion-radio>
              <ion-label>
                {{'events.export.pdf.summary.popup.type-daily-label' | translate}}
              </ion-label>
            </ion-item>
            <ion-item class="form-input" lines="none">
              <ion-radio mode="md" value="weekly"></ion-radio>
              <ion-label>
                {{'events.export.pdf.summary.popup.type-weekly-label' | translate}}
              </ion-label>
            </ion-item>
            <ion-item class="form-input" lines="none">
              <ion-radio mode="md" value="custom"></ion-radio>
              <ion-label>
                {{'events.export.pdf.summary.popup.type-custom-label' | translate}}
              </ion-label>
            </ion-item>
          </ion-radio-group>
        </ion-list>

        <ion-item-divider>
          <ion-label>
            <h2>
              {{'events.export.pdf.summary.popup.startdate-label.short' | translate}}
            </h2>
          </ion-label>
        </ion-item-divider>
        <ion-item class="form-input" lines="none" (click)="startDate.openDatetimePicker()">
          <ion-col size="1" class="form-input-icon ion-no-padding">
            <ion-label color="medium">
              <ion-icon color="primary" class="pull-left" name="custom-calendar"></ion-icon>
            </ion-label>
          </ion-col>
          <ion-col>
            <app-datetime-input #startDate [readonly]="false" [type]="'startDatetime'" [hideTime]="true" [hideShortcut]="true"
              placeholder="{{'events.export.pdf.summary.popup.startdate-label' | translate}}" formControlName="startdate">
            </app-datetime-input>
          </ion-col>
        </ion-item>
        <ion-item [hidden]="formGroup.controls['type'].value === 'daily'" class="form-input" lines="none" (click)="endDate.openDatetimePicker()">
          <ion-col size="1" class="form-input-icon ion-no-padding">
            <ion-label color="medium">
              <ion-icon color="primary" class="pull-left" name="custom-calendar"></ion-icon>
            </ion-label>
          </ion-col>
          <ion-col>
            <app-datetime-input #endDate [readonly]="(formGroup.controls['type'].value !== 'custom'? true: false)" [type]="'endDatetime'" [hideTime]="true"
              [placeholder]="(formGroup.controls['type'].value === 'custom' ? ('events.export.pdf.summary.popup.enddate-label' | translate) : '')" formControlName="enddate">
            </app-datetime-input>
          </ion-col>
        </ion-item>
        <div class="left-margin">
          <div *ngIf="isDateDurationInvalid" class="flex-row justify-content-end ">
            <span class="validation-error text-danger" >
              {{'form.error.event-and-task.negativeDuration' | translate}}
            </span>
          </div>
          <div *ngIf="isDateRangeInvalid && !isDateDurationInvalid">
            <span class="validation-error text-danger">
              {{'events.report.error.one_month_duration' | translate}}
            </span>
          </div>
        </div>

        <ion-item-divider>
          <ion-label>
            <h2>
              {{'events.export.pdf.summary.popup.title-label.short' | translate}}
            </h2>
          </ion-label>
        </ion-item-divider>
        <ion-input formControlName="title" placeholder="{{'events.export.pdf.summary.popup.title-label' | translate}}" type="text"></ion-input>
      </div>
      
      <ion-item-divider>
        <ion-label (click)="isCollapsedData = !isCollapsedData" [attr.aria-expanded]="!isCollapsedData" aria-controls="collapseData">
          <h1 class="pull-left">{{ 'events.export-report-data' | translate }}</h1>
          <ion-icon class="pull-right" [attr.name]="isCollapsedData ? 'chevron-down' : 'chevron-up'"></ion-icon>
        </ion-label>
      </ion-item-divider>

      <div class="seperator"></div>
      
      <div id="collapseData" [ngbCollapse]="isCollapsedData">
        <ion-item-divider>
          <ion-label>
            <h2>
              {{ 'custom_event_form.mandatory_information' | translate }}
            </h2>
          </ion-label>
        </ion-item-divider>
        <ion-list>
          <ion-item lines="none" *ngFor="let item of mandatoryFields">
            <ion-checkbox [checked]="true" [disabled]="true"></ion-checkbox>
            <ion-label>{{ item.label | translate }}</ion-label>
          </ion-item>
        </ion-list>
        
        <ion-item-divider>
          <ion-label>
            <h2>
              {{ 'report.site_diary.activities' | translate }}
            </h2>
          </ion-label>
        </ion-item-divider>
        <ion-list>
          <ion-item lines="none" *ngFor="let item of activitiesFields">
            <ion-checkbox 
              [checked]="selectedOptions.includes(item.controlName)" 
              (ionChange)="toggleSelection(item.controlName)">
            </ion-checkbox>
            <ion-label>{{ item.label | translate }}</ion-label>
          </ion-item>
        </ion-list>
        
        <ion-item-divider>
          <ion-label>
            <h2>
              {{ 'menu.resources' | translate }}
            </h2>
          </ion-label>
        </ion-item-divider>
        <ion-list>
          <ion-item lines="none" *ngFor="let item of resourcesFields">
            <ion-checkbox 
              [checked]="selectedOptions.includes(item.controlName)" 
              (ionChange)="toggleSelection(item.controlName)">
            </ion-checkbox>
            <ion-label>{{ item.label | translate }}</ion-label>
          </ion-item>
        </ion-list>
      </div>
    </form>
  </ion-content>
</ng-container>
