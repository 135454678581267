import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventAsset } from '@models/event-asset';
import { EventLegacyFieldComponent } from '../event-legacy-field.component';

@Component({
  selector: 'app-event-equipment',
  templateUrl: './event-equipment.component.html',
  styleUrls: ['./event-equipment.component.scss', '../event-legacy-field.component.sass']
})
export class EventEquipmentComponent extends EventLegacyFieldComponent implements OnInit {

  @Input() plants: EventAsset[];
  @Input() plantsLoaded: boolean;
  
  constructor(
    protected modalController: ModalController,
  ) { 
    super(
      modalController,
    )
  }

  ngOnInit() {
    this.plants = this.plants.filter((plant) => !plant.isArchived);
  }

}
