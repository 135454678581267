import { ModelElement } from './model-element';

export class Contractor implements ModelElement {
  id: string;
  revision: string;
  name: string;
  siteId: string;
  isArchived: boolean;

  constructor() {
    this.id = ModelElement.generateId();
    this.revision = '';
    this.name = '';
    this.siteId = 'null';
    this.isArchived = false;
  }

  /**
   * Extract {@link Contractor} from json
   * @param json given json
   * @param model given Contractor
   */
  public static toModel(json: any, contractor: Contractor) {
    contractor.id = json.payload.id;
    contractor.name = json.payload.name;
    contractor.siteId = json.payload.siteId ? json.payload.siteId : 'null';
    contractor.isArchived = json.payload.isArchived;
  }

  /**
   * Sort {@link Contractor} by name
   * @param contractor1 first contractor to compare
   * @param contractor2 second contractor to compare
   */
  public static compareByName(contractor1, contractor2) {
    return contractor1.name.localeCompare(contractor2.name);
  }

  /**
   * Copy given {@link Contractor} into destination {@link Contractor}
   * @param destination given destination Contractor
   * @param source given source Contractor
   */
  public static copy(destination: Contractor, source: Contractor): void {
    destination.id = source.id;
    destination.revision = source.revision;
    destination.name = source.name;
    destination.siteId = source.siteId;
    destination.isArchived = source.isArchived;
  }

  /** Get json from {@link Contractor} */
  public toDTO() {
    return {
      id: this.id,
      name: this.name,
      siteId: this.siteId !== 'null' ? this.siteId : null,
      isArchived: this.isArchived
    };
  }

  public get label(): string {
    return this.name;
  }
}
