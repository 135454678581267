import { ModelElement } from './model-element';

export enum AssetCategory {
  MATERIALS = 'Materials',
  EQUIPMENTS = 'Equipment',
  LABOURS = 'Labours'
}

export enum AssetCategoryNames {
  PEOPLE = 'people',
  EQUIPMENTS = 'equipments',
  MATERIALS = 'materials'
}

export enum ResourceTypeNames {
  PEOPLE = 'labours',
  EQUIPMENTS = 'plants',
  MATERIALS = 'materials'
}

export enum InformationCategory {
  TAGS = 'Tags',
  CONTRACTORS = 'Contractors',
  LOCATIONS = 'Locations'
}

export enum Tabs {
  ACTIVE_RESOURCES = "Active",
  ARCHIVED_RESOURCES = "Archived",
}

export interface AdminAsset {
  name: string;
}

export class Asset implements ModelElement {
  id: string;
  revision: string;
  category: string;
  name: string;
  cost: number;
  quantityUnit: string;
  siteId: string;
  jobRole?: string;
  supplier?: string;
  isArchived?: boolean;

  constructor() {
    this.id = ModelElement.generateId();
    this.revision = '';
    this.category = '';
    this.name = '';
    this.cost = 0;
    this.siteId = 'null';
    this.quantityUnit = null;
    this.jobRole = null;
    this.supplier = null;
    this.isArchived = false;
  }

  /**
  * Convert backend json to MODEL
  * @param json json returned by backend sync/ websocket
  * @param model Given Asset
  */
  public static toModel(json: any, model: Asset) {
    model.id = json.payload.id;
    model.category = json.payload.assetCategory;
    model.name = json.payload.name;
    model.cost = json.payload.defaultCost;
    model.quantityUnit = json.payload.quantityUnit;
    model.siteId = json.payload.siteId ? json.payload.siteId : 'null';
    model.jobRole = json.payload.jobRole;
    model.supplier = json.payload.supplier;
    model.isArchived = json.payload.isArchived;
  }

  /**
  * Convert frontend model to backend model
  */
  toDTO() {
    return {
      id: this.id,
      assetCategory: this.category,
      name: this.name,
      defaultCost: this.cost,
      quantityUnit: this.quantityUnit,
      siteId: this.siteId !== 'null' ? this.siteId : null,
      jobRole: this.jobRole,
      supplier: this.supplier,
      isArchived: this.isArchived
    };
  }

  get label(): string {
    return this.name;
  }
}
