import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ExportService, SummaryType } from '@services/export.service';
import { positiveDurationValidator } from '@validators/positive-duration.validator';
import { ExportReportFilter, ReportFields } from '../event-report-filter/event-report-filter.component';

type SuccessCallback = (form: FormGroup) => void;
type DismissCallback = () => void;

@Component({
  selector: 'app-report-export-configuration-popup',
  templateUrl: './report-export-configuration-popup.component.html',
  styleUrls: ['./report-export-configuration-popup.component.sass'],
})

export class ReportExportConfigurationPopupComponent implements OnInit {

  formGroup: FormGroup;
  isCollapsedGeneral = false;
  isCollapsedData = false;
  isReportPdf: boolean = true;

  // Arrays for checkbox sections
  mandatoryInfo = [
    { label: 'events.list.site_name' },
    { label: 'label.date' },
    { label: 'summary-report.site_diary.prepared_by' },
    { label: 'report.weather' }
  ];

  activitiesInfo = [
    { controlName: ReportFields.CONTRACTORS, label: 'report.contractors' },
    { controlName: ReportFields.LOCATIONS, label: 'label.locations' },
    { controlName: ReportFields.TAGS, label: 'report.tags' },
    { controlName: ReportFields.PHOTOS, label: 'events.preview.section.photos' }
  ];

  resources = [
    { controlName: ReportFields.PEOPLE, label: 'report.people' },
    { controlName: ReportFields.EQUIPMENT, label: 'report.equipment' },
    { controlName: ReportFields.MATERIAL, label: 'report.materials' }
  ];

  @Input() protected successCallback: SuccessCallback;
  @Input() protected dismissCallback: DismissCallback;
  @Input() isSingleEvent: boolean = false;

  constructor(private fb: FormBuilder, private exportService: ExportService) {
    this.formGroup = this.fb.group({
      format: [ExportReportFilter.PDF],
      startDatetime: [null, Validators.required],
      endDatetime: [null, Validators.required],
      documentName: [''],
      mandatoryInformation: this.fb.array(this.mandatoryInfo.map(item => item.label)), // Extract only controlName
      activitiesInformation: this.fb.array(this.activitiesInfo.map(item => item.controlName)), // Extract only controlName
      resources: this.fb.array(this.resources.map(item => item.controlName)), // Extract only controlName
    }, {
      validator: [positiveDurationValidator],
    });

    this.formGroup.controls['format'].valueChanges.subscribe((format) => {
      this.isReportPdf = format === ExportReportFilter.PDF;
      if (this.isReportPdf) {
        this.formGroup.addControl('startDatetime', new FormControl(null, Validators.required));
        this.formGroup.addControl('endDatetime', new FormControl(null, Validators.required));
      } else {
        this.formGroup.removeControl('startDatetime');
        this.formGroup.removeControl('endDatetime');
      }
    });
  }

  get mandatoryInformation(): FormArray {
    return this.formGroup.get('mandatoryInformation') as FormArray;
  }

  get activitiesInformation(): FormArray {
    return this.formGroup.get('activitiesInformation') as FormArray;
  }

  get resourcesArray(): FormArray {
    return this.formGroup.get('resources') as FormArray;
  }

  isExportButtonDisabled() {
    if (this.isSingleEvent) {
      return false
    } return this.formGroup.invalid
  }

  // Get selected values from any array
  toggleSelection(array: FormArray, value: string, event: any) {
    if (event.detail.checked) {
      if (!array.value.includes(value)) {
        array.push(this.fb.control(value)); // Add name to array
      }
    } else {
      const index = array.value.indexOf(value);
      if (index > -1) {
        array.removeAt(index); // Remove name from array
      }
    }
  }
  ngOnInit() {}

  setCallbacks(
    success: SuccessCallback,
    dismiss?: DismissCallback,
  ): void {
    this.successCallback = success;
    this.dismissCallback = dismiss;
  }

  dismiss(): void {
    if (this.dismissCallback) {
      this.dismissCallback();
    }
  }

  download(): void {
    if (!this.formGroup.invalid && !this.isSingleEvent) {
      const startDatetime = this.formGroup.value['startDatetime'];
      const endDatetime = this.formGroup.value['endDatetime'];
      if (this.exportService.checkPdfReportDuration(startDatetime, endDatetime)) {
        this.successCallback(this.formGroup);
      }
    } else if (this.isSingleEvent) {
      this.successCallback(this.formGroup);
    }
  }

}
